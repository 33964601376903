import { useEffect, useRef } from 'react'
import './App.scss'
import LinkList from './components/LinkList/LinkList'

function App () {
  const wrapper = useRef(null)
  useEffect(() => {
    const bgiContainer = wrapper.current
    if (bgiContainer) {
      var avif = new Image()
      avif.src =
        'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='
      avif.onload = () => {
        bgiContainer.classList.add('avif')
      }
      avif.onerror = () => {
        bgiContainer.classList.add('webp')
      }
    }
  }, [])
  return (
    <div className='spokpojken' ref={wrapper}>
      <header className='mainTitle__wrapper'>
        <h1 className='mainTitle'>Spökpojken</h1>
        <p className="subTitle">new wave • new romantic • new music</p>
      </header>
      <LinkList />
    </div>
  )
}

export default App
