import React from 'react'

const LinkList = () => {
  const links = [
    {
      url: 'mailto:spokpojken@gmail.com',
      icon: 'icon-mail-envelope-closed',
      label: 'contact spökpojken'
    },
    {
      url:
        'https://open.spotify.com/album/1t6RKrWVluwxUhMZeTXqVg?si=yJ_zmkhSRbexm-W9Y14OXA',
      icon: 'icon-spotify',
      label: 'listen on spotify!'
    },
    {
      url: 'https://spokpojken.bandcamp.com',
      icon: 'icon-bandcamp',
      label: 'listen on bandcamp!'
    }
  ]
  return (
    <ul className="linkList">
      {links.map(({ url, icon, label }, index) => (
        <li
          key={url}
          target='_blank'
          className='link'
          >
        <a
          href={url}
          style={{ animationDelay: `${index * 2}s` }}
          rel='noreferrer'
        >
          <i className={`icon ${icon}`} /> {label}
        </a>
        </li>
      ))}
      </ul>)
}

export default LinkList
